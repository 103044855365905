import { Injectable } from "@angular/core";
import { Subject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class EventService {
  constructor() {}

  public eventSubject = new Subject<any>();
  public bookingSubject = new Subject<any>();
  public chatSubject = new Subject<any>();
  public kycSubject = new Subject<any>();

  publishEvent(data: any) {
    this.eventSubject.next(data);
  }

  getEvent(): Subject<any> {
    return this.eventSubject;
  }

  bookingEvent(data: any) {
    this.bookingSubject.next(data);
  }

  getbookingEvent(): Subject<any> {
    return this.bookingSubject;
  }

  chatEvent(data: any) {
    this.chatSubject.next(data);
  }

  getChatEvent(): Subject<any> {
    return this.chatSubject;
  }
  getKyc(): Subject<any> {
    return this.kycSubject;
  }
  kycEvent(data: any) {
    this.kycSubject.next(data);
  }
}
