import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpHeaders,
} from "@angular/common/http";
import { map } from "rxjs/operators";
import {
  AlertController,
  LoadingController,
  NavController,
  ToastController,
} from "@ionic/angular";
import { Platform } from "@ionic/angular";
//Local
// let Url = "https://homeshowing.betaplanets.com/back-end/";
// let fullUrl =
//   "https://homeshowing.betaplanets.com/back-end/wp-json/mobileapi/v1/";
// let authurl =
//   "https://homeshowing.betaplanets.com/back-end/wp-json/jwt-auth/v1/";
//LIVE 
let Url = "https://app.homeshowingpros.com/back-end/";
let fullUrl = "https://app.homeshowingpros.com/back-end/wp-json/mobileapi/v1/";
let authurl = "https://app.homeshowingpros.com/back-end/wp-json/jwt-auth/v1/";


@Injectable({
  providedIn: "root",
})
export class UserService {
  loading: any;
  one_id;
  token;
  type;
  iana_timezone: any;

  constructor(
    private http: HttpClient,
    public loadingCtrl: LoadingController,
    public alertCtrl: AlertController,
    public toastController: ToastController,
    public navCtrl: NavController,
    private platform: Platform
  ) {}
  getURL() {
    return Url;
  }

  async presentAlert(msg) {
    let alert = await this.alertCtrl.create({
      message: msg,
      buttons: [
        {
          text: "OK",
          handler: () => {},
        },
      ],
    });

    await alert.present();
  }

  async singleShowingCardAlert(msg) {
    let alert = await this.alertCtrl.create({
      message: msg,
      buttons: [
        {
          text: "OK",
          handler: () => {
            this.navCtrl.navigateForward("cards");
          },
        },
      ],
    });

    await alert.present();
  }

  async BankingInfoAlert(msg) {
    let alert = await this.alertCtrl.create({
      message: msg,
      buttons: [
        {
          text: "Cancel",
          handler: () => {
            this.navCtrl.navigateForward("tabs/dashboard");
          },
        },
        {
          text: "OK",
          handler: () => {
            this.navCtrl.navigateForward("kyc");
          },
        },
      ],
    });

    await alert.present();
  }

  async alertMessage(msg) {
    let alert = await this.alertCtrl.create({
      message: msg,
      buttons: [
        {
          text: "Cancel",
          handler: () => {
            this.navCtrl.navigateForward("showing");
          },
        },
        {
          text: "OK",
          handler: () => {
            this.navCtrl.navigateForward("kyc");
          },
        },
      ],
    });

    await alert.present();
  }

  async dismissLoading() {
    await this.loading.dismiss();
  }

  async showLoader(msg: string = "") {
    if (msg == "") {
      msg = "Please wait...";
    }
    this.loading = await this.loadingCtrl.create({ message: msg });
    await this.loading.present();
  }
  async presentToast(msg) {
    const toast = await this.toastController.create({
      message: msg,
      duration: 3000,
      position: "bottom",
    });
    toast.present();
    // this.router.navigate(page);
  }
  getData(endPoint) {
    return this.http.get(fullUrl + endPoint).pipe(
      map((data) => {
        return data;
      })
    );
  }
  sendMedia(endPoint, data, token) {
    const HttpUploadOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + token }),
    };
    return this.http.post(fullUrl + endPoint, data, HttpUploadOptions).pipe(
      map((data) => {
        return data;
      })
    );
  }
  getSecoondUserInfo1(token, id) {
    return this.http.post(Url + "wp-json/mobileapi/v1/getSecoondUserInfo1", {
      token: token,
      id: id,
    });
  }

  agentRating(ratingInfo) {
    return this.http.post(
      Url + "wp-json/mobileapi/v1/author_rating_agent",
      ratingInfo
    );
  }

  sendData(endPoint, data) {
    return this.http.post(fullUrl + endPoint, data).pipe(
      map((data) => {
        return data;
      })
    );
  }

  getCMS(endPoint, data) {
    return this.http.post(fullUrl + endPoint, data).pipe(
      map((data) => {
        return data;
      })
    );
  }

  addFirmApi(endPoint, data) {
    return this.http.post(fullUrl + endPoint, data).pipe(
      map((data) => {
        return data;
      })
    );
  }

  getFirmListApi(endPoint, data) {
    return this.http.get(fullUrl + endPoint, data).pipe(
      map((data) => {
        return data;
      })
    );
  }

  sendPushNotification(value) {
    return this.http.post(
      Url + "wp-json/mobileapi/v1/sendPushNotification",
      value
    );
  }

  createBankAccountToken(data) {
    return this.http
      .post(
        "https://api.stripe.com/v1/tokens",
        "bank_account[routing_number]=" +
          data.routing_number +
          "&bank_account[account_number]=" +
          data.account_number +
          "&bank_account[account_holder_name]=" +
          data.account_holder_name +
          "&bank_account[account_holder_type]=individual&bank_account[currency]=USD&bank_account[country]=US&key=" +
          data.key,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: "Bearer " + data.key,
            accept: "application/json",
          },
        }
      )
      .pipe(
        map((data) => {
          return data;
        })
      );
  }
  createCardToken(data) {
    return this.http
      .post(
        "https://api.stripe.com/v1/tokens",
        "time_on_page=113448&guid=NA&muid=NA&sid=NA&key=" +
          data.key +
          "&payment_user_agent=stripe.js%2F6c4e062&card[name]=" +
          data.name +
          "&card[number]=" +
          data.number +
          "&card[exp_month]=" +
          data.expMonth +
          "&card[exp_year]=" +
          data.expYear +
          "&card[cvc]=" +
          data.cvc +
          "&card[currency]=USD",
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: "Bearer "+data.key,
            accept: "application/json",
          },
        }
      )
      .pipe(
        map((data) => {
          return data;
        })
      );
  }
  auth(endPoint, data) {
    return this.http.post(authurl + endPoint, data).pipe(
      map((data) => {
        return data;
      })
    );
  }
  GetSetting() {
    return this.http.post(Url + "wp-json/mobileapi/v1/GetSetting", {});
  }
  addCardtoBank(token, stripeToken) {
    return this.http.post(Url + "wp-json/mobileapi/v1/add_debit_card", {
      token: token,
      type: "card",
      stripeToken: stripeToken,
    });
  }

  checkWayApi(statusInfo) {
    return this.http.post(
      Url + "wp-json/mobileapi/v1/checkin_and_oneway",
      statusInfo
    );
  }

  completeShowingApi(statusInfo) {
    return this.http.post(
      Url + "wp-json/mobileapi/v1/complete_showing",
      statusInfo
    );
  }

  expireShowingApi(statusInfo) {
    return this.http.post(
      Url + "wp-json/mobileapi/v1/remove_showing_from_list",
      statusInfo
    );
  }

  sendFeedback(info) {
    return this.http.post(
      Url + "wp-json/mobileapi/v1/send_feedback_to_service_author",
      info
    );
  }

}
