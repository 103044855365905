import { NgModule } from "@angular/core";
import { PreloadAllModules, RouterModule, Routes } from "@angular/router";

const routes: Routes = [
  {
    path: "",
    loadChildren: () =>
      import("./pages/tabs/tabs.module").then((m) => m.TabsPageModule),
  },
  {
    path: "login",
    loadChildren: () =>
      import("./pages/login/login.module").then((m) => m.LoginPageModule),
  },
  {
    path: "usertype",
    loadChildren: () =>
      import("./pages/usertype/usertype.module").then(
        (m) => m.UsertypePageModule
      ),
  },
  {
    path: "forgotpassword",
    loadChildren: () =>
      import("./pages/forgotpassword/forgotpassword.module").then(
        (m) => m.ForgotpasswordPageModule
      ),
  },
  {
    path: "terms",
    loadChildren: () =>
      import("./pages/terms/terms.module").then((m) => m.TermsPageModule),
  },
  {
    path: "privacy",
    loadChildren: () =>
      import("./pages/privacy/privacy.module").then((m) => m.PrivacyPageModule),
  },
  {
    path: "signup",
    loadChildren: () =>
      import("./pages/signup/signup.module").then((m) => m.SignupPageModule),
  },
  {
    path: "payment",
    loadChildren: () =>
      import("./pages/payment/payment.module").then((m) => m.PaymentPageModule),
  },
  {
    path: "createaccount",
    loadChildren: () =>
      import("./pages/createaccount/createaccount.module").then(
        (m) => m.CreateaccountPageModule
      ),
  },
  {
    path: "dashboard",
    loadChildren: () =>
      import("./pages/dashboard/dashboard.module").then(
        (m) => m.DashboardPageModule
      ),
  },
  {
    path: "showing",
    loadChildren: () =>
      import("./pages/showing/showing.module").then((m) => m.ShowingPageModule),
  },
  {
    path: "createshowing",
    loadChildren: () =>
      import("./pages/createshowing/createshowing.module").then(
        (m) => m.CreateshowingPageModule
      ),
  },
  {
    path: "paymentinfo",
    loadChildren: () =>
      import("./pages/paymentinfo/paymentinfo.module").then(
        (m) => m.PaymentinfoPageModule
      ),
  },

  {
    path: "confirmation",
    loadChildren: () =>
      import("./pages/confirmation/confirmation.module").then(
        (m) => m.ConfirmationPageModule
      ),
  },
  {
    path: "editprofile",
    loadChildren: () =>
      import("./pages/editprofile/editprofile.module").then(
        (m) => m.EditprofilePageModule
      ),
  },
  {
    path: "appointmentdetail/:id",
    loadChildren: () =>
      import("./pages/appointmentdetail/appointmentdetail.module").then(
        (m) => m.AppointmentdetailPageModule
      ),
  },
  {
    path: "agentratting",
    loadChildren: () =>
      import("./pages/agentratting/agentratting.module").then(
        (m) => m.AgentrattingPageModule
      ),
  },
  {
    path: "myaccount",
    loadChildren: () =>
      import("./pages/myaccount/myaccount.module").then(
        (m) => m.MyaccountPageModule
      ),
  },
  {
    path: "history",
    loadChildren: () =>
      import("./pages/history/history.module").then((m) => m.HistoryPageModule),
  },
  {
    path: "showingdetail",
    loadChildren: () =>
      import("./pages/showingdetail/showingdetail.module").then(
        (m) => m.ShowingdetailPageModule
      ),
  },
  {
    path: "showingdetails",
    loadChildren: () =>
      import("./pages/showingdetails/showingdetails.module").then(
        (m) => m.ShowingdetailsPageModule
      ),
  },
  {
    path: "currentjob",
    loadChildren: () =>
      import("./pages/currentjob/currentjob.module").then(
        (m) => m.CurrentjobPageModule
      ),
  },
  {
    path: "wallet",
    loadChildren: () =>
      import("./pages/wallet/wallet.module").then((m) => m.WalletPageModule),
  },
  {
    path: "singleshowing/:id",
    loadChildren: () =>
      import("./pages/singleshowing/singleshowing.module").then(
        (m) => m.SingleshowingPageModule
      ),
  },
  {
    path: "agentlist",
    loadChildren: () =>
      import("./pages/agentlist/agentlist.module").then(
        (m) => m.AgentlistPageModule
      ),
  },
  {
    path: "review",
    loadChildren: () =>
      import("./pages/review/review.module").then((m) => m.ReviewPageModule),
  },
  {
    path: "contactus",
    loadChildren: () =>
      import("./pages/contactus/contactus.module").then(
        (m) => m.ContactusPageModule
      ),
  },
  {
    path: "news",
    loadChildren: () =>
      import("./pages/news/news.module").then((m) => m.NewsPageModule),
  },
  {
    path: "requestoraccept",
    loadChildren: () =>
      import("./pages/requestoraccept/requestoraccept.module").then(
        (m) => m.RequestoracceptPageModule
      ),
  },
  {
    path: "profile",
    loadChildren: () =>
      import("./pages/profile/profile.module").then((m) => m.ProfilePageModule),
  },
  {
    path: "about",
    loadChildren: () =>
      import("./pages/about/about.module").then((m) => m.AboutPageModule),
  },
  {
    path: "singlenews",
    loadChildren: () =>
      import("./pages/singlenews/singlenews.module").then(
        (m) => m.SinglenewsPageModule
      ),
  },
  {
    path: "kyc",
    loadChildren: () =>
      import("./pages/kyc/kyc.module").then((m) => m.KycPageModule),
  },
  {
    path: "addmoney",
    loadChildren: () =>
      import("./pages/addmoney/addmoney.module").then(
        (m) => m.AddmoneyPageModule
      ),
  },
  {
    path: "addbank",
    loadChildren: () =>
      import("./pages/addbank/addbank.module").then((m) => m.AddbankPageModule),
  },
  {
    path: "withdrawmoney",
    loadChildren: () =>
      import("./pages/withdrawmoney/withdrawmoney.module").then(
        (m) => m.WithdrawmoneyPageModule
      ),
  },
  {
    path: "cards",
    loadChildren: () =>
      import("./pages/cards/cards.module").then((m) => m.CardsPageModule),
  },
  {
    path: "banks",
    loadChildren: () =>
      import("./pages/banks/banks.module").then((m) => m.BanksPageModule),
  },
  {
    path: "editbanks",
    loadChildren: () =>
      import("./pages/editbanks/editbanks.module").then(
        (m) => m.EditbanksPageModule
      ),
  },
  {
    path: "editcards",
    loadChildren: () =>
      import("./pages/editcards/editcards.module").then(
        (m) => m.EditcardsPageModule
      ),
  },
  {
    path: "chat",
    loadChildren: () =>
      import("./pages/chat/chat.module").then((m) => m.ChatPageModule),
  },
  {
    path: "profile",
    loadChildren: () =>
      import("./pages/profile/profile.module").then((m) => m.ProfilePageModule),
  },
  {
    path: "profilenew/:id",
    loadChildren: () =>
      import("./pages/profilenew/profilenew.module").then(
        (m) => m.ProfilenewPageModule
      ),
  },
  {
    path: "agentrating/:id",
    loadChildren: () =>
      import("./pages/agentratting/agentratting-routing.module").then(
        (m) => m.AgentrattingPageRoutingModule
      ),
  },
  {
    path: "editkyc",
    loadChildren: () =>
      import("./pages/editkyc/editkyc.module").then((m) => m.EditkycPageModule),
  },
  {
    path: "editshowing/:id",
    loadChildren: () =>
      import("./pages/editshowing/editshowing.module").then(
        (m) => m.EditshowingPageModule
      ),
  },
  {
    path: "stripe-ssa",
    loadChildren: () =>
      import("./pages/stripe-ssa/stripe-ssa.module").then(
        (m) => m.StripeSsaPageModule
      ),
  },
  {
    path: "history-detail/:id",
    loadChildren: () =>
      import("./pages/history-detail/history-detail.module").then(
        (m) => m.HistoryDetailPageModule
      ),
  },
  {
    path: "requestoraccept",
    loadChildren: () =>
      import("./pages/requestoraccept/requestoraccept.module").then(
        (m) => m.RequestoracceptPageModule
      ),
  },
  {
    path: 'change-pass',
    loadChildren: () => import('./pages/change-pass/change-pass.module').then( m => m.ChangePassPageModule)
  },
  {
    path: 'current-listing',
    loadChildren: () => import('./pages/current-listing/current-listing.module').then( m => m.CurrentListingPageModule)
  },
  {
    path: 'update-kyc',
    loadChildren: () => import('./update-kyc/update-kyc.module').then( m => m.UpdateKycPageModule)
  },

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
