import { ChangeDetectorRef, Component, NgZone, OnInit, ViewChild } from "@angular/core";

import {
  Platform,
  NavController,
  AlertController,
  MenuController,
} from "@ionic/angular";
import { SplashScreen } from "@ionic-native/splash-screen/ngx";
import { StatusBar } from "@ionic-native/status-bar/ngx";
import "firebase/messaging";
import * as firebase from "firebase";
import { environment } from "src/environments/environment";
import { Router } from "@angular/router";
import { UserService } from "./services/user/user.service";
import { EventService } from "./services/events/event.service";
import { PrefService } from "./services/common/pref.service";
import { CommonService } from "./services/common/common.service";
import { InAppBrowser, InAppBrowserEvent, InAppBrowserObject, } from "@ionic-native/in-app-browser/ngx";

@Component({
  selector: "app-root",
  templateUrl: "app.component.html",
  styleUrls: ["app.component.scss"],
})
export class AppComponent implements OnInit {
  @ViewChild("myTooltip", { static: false }) myTooltip;

  userDetail;
  userInfo;
  editBanking: boolean = false;
  addBanking: boolean = false;
  public stripe_url: any;
  // public redirect: any = "https://app.homeshowingpros.com/tabs/dashboard/";
  public redirect: any = window.location.origin+"/tabs/dashboard/";
  public second_url: any;
  public final_token_url: any;
  public final_url: any;
  public userId: any;
  public shared_url: any;
  constructor(
    private platform: Platform,
    private menu: MenuController,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    public nav: NavController,
    private userService: UserService,
    public eventService: EventService,
    public pref: PrefService,
    public alert: AlertController,
    private router: Router,
    private zone: NgZone,
    private common: CommonService,
    private browser: InAppBrowser,
    private cdref : ChangeDetectorRef

  ) {
    this.initializeApp();
    this.eventService.getEvent().subscribe((res) => {
      if (res["kyc_status"] == 1) {
        this.editBanking = true;
        this.addBanking = false;
      } else {
        this.addBanking = true;
        this.editBanking = false;
      }
      this.getProfile();
    });
  }
  initializeApp() {
    this.zone.run(() => {
      this.platform.ready().then(() => {
        this.statusBar.styleDefault();
        this.splashScreen.hide();
        this.shared_url = window.location.hash;
        // console.log("sgared", this.shared_url);
        if (this.shared_url.includes('#/tabs/dashboard?code=')) {
          this.pref.setStorageItem("stripe_url_token", this.shared_url.replace("#/tabs/dashboard?code=", ""));
        } else {
          this.pref.removeStorageItem("stripe_url_token");
        }
        this.userInfo = this.pref.getStorageItem("user_res");
        if (this.userInfo != null && this.userInfo != undefined) {
          if (
            this.userInfo.completed_steps == "1" ||
            this.userInfo.completed_steps == "2" ||
            this.userInfo.completed_steps == "3"
          ) {
            this.router.navigate(["createaccount"]);
          } else {
            this.router.navigate(["tabs/dashboard"]);
            this.getProfile();
          }
        } else {
          this.router.navigate(["usertype"]);
        }

        // // Initialize Firebase
        firebase.initializeApp(environment.firebase);
        firebase.analytics();

        const messaging = firebase.messaging();
        messaging
          .requestPermission()
          .then((res) => {
            return messaging.getToken();
          })
          .then((token) => {})
          .catch((err) => {});
        messaging.onMessage((payload) => {});
        self.addEventListener("notificationclick", function (event) {

        });
      });
    });
  }
  ngOnInit() {
    this.eventService.getEvent().subscribe((res) => {
      if (res["event"] == "updateKYC") {
        this.getProfile();
      } 
    });
  }

  getProfile() {
    const option = {
      date: this.common.getSystemDateTime(),
    };
    this.userService
      .sendData("getProfile?token=" + this.userInfo.token, option)
      .subscribe((res) => {
        this.eventService.bookingEvent(res);
        this.userDetail = res;
        this.pref.setStorageItem("kyc_status", res["kyc_status"]);
        if (res["kyc_status"] == 1) {
          this.editBanking = true;
          this.addBanking = false;
        } else {
          this.addBanking = true;
          this.editBanking = false;
        }
        this.cdref.detectChanges();

      });
  }

  async DoLogout() {
    let alert = await this.alert.create({
      header: "Logout",
      message: "Are you sure you want to logout?",
      buttons: [
        {
          text: "Cancel",
          handler: () => {},
        },
        {
          text: "Logout",
          handler: () => {
            this.pref.cleanAppData();
            this.menu.enable(false, "leftSide");
            this.nav.navigateForward(["/login"]);
          },
        },
      ],
    });
    await alert.present();
  }

  async goToCards(res: any) {
    await this.menu.toggle();
    if (res == 'kyc') {
      await this.dobank()
    } else if (res == 'editkyc') {
      // await this.presentAlertConfirm();
    } else {
      await this.router.navigate([res]);
    }
  }

  async goToEditKyc(){
    await this.menu.toggle();
    this.nav.navigateForward(["/update-kyc"]);
  }

  async openMenu() {

    await this.zone.run(async () => {
      this.userInfo = await this.pref.getStorageItem("user_res");
      let kyc = this.pref.getStorageItem("kyc_status");
      if (kyc == 1) {
        this.editBanking = true;
        this.addBanking = false;
      } else {
        this.editBanking = false;
        this.addBanking = true;
      }
      this.menu.enable(true, "leftSide");


    });
  }

  dobank() {
    this.zone.run(async () => {
      this.userInfo = await this.pref.getStorageItem("user_res");
      if (this.userInfo != null && this.userInfo != undefined) {
        if (this.userInfo.user_id == '233' || this.userInfo.user_id == '234' || this.userInfo.user_id == '235' || this.userInfo.user_id == '241' || this.userInfo.user_id == '242' || this.userInfo.user_id == '243' || this.userInfo.user_id == '246' || this.userInfo.user_id == '247') {
          this.stripe_url =
            "https://connect.stripe.com/express/oauth/authorize?redirect_uri=" +
            this.redirect +
            this.userInfo.user_id +
            "&client_id=" +
            "ca_JDplYLCzhOxGo9Cxil5a1HtJcQEYllCk"
            + "&requested_capabilities[]=transfers";
        } else {
          this.stripe_url =
            "https://connect.stripe.com/express/oauth/authorize?redirect_uri=" +
            this.redirect +
            this.userInfo.user_id +
            "&client_id=" +
            "ca_JDplL2ijBf9s768ptdzttHthMWqfsdlb"
            + "&requested_capabilities[]=transfers";
        }

        const browser: InAppBrowserObject = this.browser.create(
          this.stripe_url,
          "_self",
          {
            location: "no",
            clearcache: "yes",
            fullscreen: "yes",
            useWideViewPort: "yes",
            clearsessioncache: "yes",
            closebuttoncaption: "cancel",
          }
        );
        const onLoadStart = browser
          .on("loadstart")
          .subscribe((event: InAppBrowserEvent) => {

            if (event.url) {
              if (event.url.includes("?code=")) {
                this.second_url = event.url.substring(event.url.indexOf("="));
                this.final_token_url = this.second_url.split("=").pop();
                if (this.userInfo.user_id == '233' || this.userInfo.user_id == '234' || this.userInfo.user_id == '235' || this.userInfo.user_id == '241' || this.userInfo.user_id == '242' || this.userInfo.user_id == '243' || this.userInfo.user_id == '246' || this.userInfo.user_id == '247')  {
                  this.final_url =
                    "https://connect.stripe.com/oauth/token?client_secret=" +
                    // "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" +
                    "sk_test_51HXObUIDtO4t4xfNwZPeQLb47XnKJD8ITA7L3K0FZ4X5ApJrybUr5IKIQRtbukcSGIOVsYRnqEuTp2JdAaGAzV1k00DYgebzxk" +
                    "&code" +
                    this.second_url +
                    "&grant_type=authorization_code";
                } else {
                  this.final_url =
                    "https://connect.stripe.com/oauth/token?client_secret=" +
                    // "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" +

                    "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"+
                    
                    // "sk_test_51HXObUIDtO4t4xfNwZPeQLb47XnKJD8ITA7L3K0FZ4X5ApJrybUr5IKIQRtbukcSGIOVsYRnqEuTp2JdAaGAzV1k00DYgebzxk" +
                    "&code" +
                    this.second_url +
                    "&grant_type=authorization_code";
                }

                this.pref.setStorageItem("stripe_url_token", this.final_token_url);
                this.callbankstripetokenapi(this.final_token_url);
                setTimeout(() => {
                  onLoadStart.unsubscribe();
                  browser.close();
                }, 2000);
              }
            } else {

            }
          });

        // const onLoadStop = browser
        //   .on("loadstop")
        //   .subscribe((event: InAppBrowserEvent) => {

        //     if (event.url) {
        //       if (event.url.includes("?code=")) {
        //         this.second_url = event.url.substring(event.url.indexOf("="));
        //         this.final_token_url = this.second_url.split("=").pop();
        //         if (this.userInfo.user_id == '233' || this.userInfo.user_id == '234' || this.userInfo.user_id == '235' || this.userInfo.user_id == '241' || this.userInfo.user_id == '242' || this.userInfo.user_id == '243') {
        //           this.final_url =
        //             "https://connect.stripe.com/oauth/token?client_secret=" +
        //             // "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" +
        //             "sk_test_51HXObUIDtO4t4xfNwZPeQLb47XnKJD8ITA7L3K0FZ4X5ApJrybUr5IKIQRtbukcSGIOVsYRnqEuTp2JdAaGAzV1k00DYgebzxk" +
        //             "&code" +
        //             this.second_url +
        //             "&grant_type=authorization_code";
        //         } else {
        //           this.final_url =
        //             "https://connect.stripe.com/oauth/token?client_secret=" +
        //             "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" +
        //             // "sk_test_51HXObUIDtO4t4xfNwZPeQLb47XnKJD8ITA7L3K0FZ4X5ApJrybUr5IKIQRtbukcSGIOVsYRnqEuTp2JdAaGAzV1k00DYgebzxk" +
        //             "&code" +
        //             this.second_url +
        //             "&grant_type=authorization_code";
        //         }
        //         this.pref.setStorageItem("stripe_url_token", this.final_token_url);

        //         this.callbankstripetokenapi(this.final_token_url);
        //         setTimeout(() => {
        //           onLoadStop.unsubscribe();
        //           browser.close();
        //         }, 2000);
        //       }
        //     } else {

        //     }
        //   });

        // const onLoadError = browser
        //   .on("loaderror")
        //   .subscribe((event: InAppBrowserEvent) => {

        //     if (event.url) {
        //       if (event.url.includes("?code=")) {
        //         this.second_url = event.url.substring(event.url.indexOf("="));
        //         this.final_token_url = this.second_url.split("=").pop();
        //         if (this.userInfo.user_id == '233' || this.userInfo.user_id == '234' || this.userInfo.user_id == '235' || this.userInfo.user_id == '241' || this.userInfo.user_id == '242' || this.userInfo.user_id == '243') {
        //           this.final_url =
        //             "https://connect.stripe.com/oauth/token?client_secret=" +
        //             // "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" +
        //             "sk_test_51HXObUIDtO4t4xfNwZPeQLb47XnKJD8ITA7L3K0FZ4X5ApJrybUr5IKIQRtbukcSGIOVsYRnqEuTp2JdAaGAzV1k00DYgebzxk" +
        //             "&code" +
        //             this.second_url +
        //             "&grant_type=authorization_code";
        //         } else {
        //           this.final_url =
        //             "https://connect.stripe.com/oauth/token?client_secret=" +
        //             "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" +
        //             // "sk_test_51HXObUIDtO4t4xfNwZPeQLb47XnKJD8ITA7L3K0FZ4X5ApJrybUr5IKIQRtbukcSGIOVsYRnqEuTp2JdAaGAzV1k00DYgebzxk" +
        //             "&code" +
        //             this.second_url +
        //             "&grant_type=authorization_code";
        //         }
        //         this.pref.setStorageItem("stripe_url_token", this.final_token_url);

        //         this.callbankstripetokenapi(this.final_token_url);
        //         setTimeout(() => {
        //           onLoadError.unsubscribe();
        //           browser.close();
        //         }, 2000);
        //       }
        //     } else {

        //     }
        //   });

        browser.on("exit").subscribe(
          () => {
            if (this.final_url != undefined) {
              this.pref.setStorageItem("stripe_url_token", this.final_token_url);

              this.callbankstripetokenapi(this.final_token_url);
            }
          },
          (err) => {
            console.error(err);
          }
        );
      }

    })

  }

  callbankstripetokenapi(token) {
    setTimeout(() => {
      let userData = this.pref.getStorageItem("user_res");
      if (userData) {
        this.userId = userData.token;
      }
    }, 1000);
    const body = {
      token: this.userId,
      stripe_token: token,
    };

    if (!navigator.onLine) {
      return this.common.presentAlert("Please check your internet connection.");
    } else {

      this.zone.run(() => {
        this.userService.sendData('verify_kyc_callback_new', body).subscribe(
          (res) => {


            if (res["status"] == "ok") {
              this.pref.setStorageItem("kyc_status", res["kyc_status"]);
              this.pref.removeStorageItem("stripe_url_token");
              this.userService.dismissLoading();
              // this.eventService.publishEvent(res);
              // this.navCtrl.back();
            } else {
              this.userService.dismissLoading();
              this.userService.presentAlert("Something went Wrong");
            }
          },
          (err) => {
            console.log(err);
            this.userService.dismissLoading();
            // this.userService.presentAlert('Something went Wrong')
            this.userService.presentAlert(err.error.errormsg);
          }
        );
      });
    }
  }


}



// https://app.homeshowingpros.com/back-end/wp-admin/admin.php?page=stripe-keys
// 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
// pk_live_51HXObUIDtO4t4xfNdD7yeemp3UWZq7daSR90uco5j7CnCxwqDiRkCwR3BEV3P64WYuUNjM6FDrl6kuGm3vHkcmOv00659cXF2b