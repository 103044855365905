import { CommonModule } from "@angular/common";
import {
  NgModule,
  CUSTOM_ELEMENTS_SCHEMA,
  NO_ERRORS_SCHEMA,
} from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule } from "@angular/common/http";
import { RouterModule } from "@angular/router";

import { IonicModule } from "@ionic/angular";
import { LocationSelectPage } from "../pages/location-select/location-select.page";
import { PopupReviewComponent } from "../pages/popup-review/popup-review.component";
import { ShowingfeedbackPage } from "../pages/showingfeedback/showingfeedback.page";
import { RescheduleShowingPage } from "../pages/reschedule-showing/reschedule-showing.page";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    RouterModule,
    IonicModule,
  ],
  declarations: [
    LocationSelectPage,
    PopupReviewComponent,
    ShowingfeedbackPage,
    RescheduleShowingPage,
  ],
  exports: [
    LocationSelectPage,
    PopupReviewComponent,
    RescheduleShowingPage,
    FormsModule,
    CommonModule,
    ReactiveFormsModule,
    HttpClientModule,
    RouterModule,
  ],
  entryComponents: [
    LocationSelectPage,
    PopupReviewComponent,
    ShowingfeedbackPage,
    RescheduleShowingPage,

  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class SharedModule {}
