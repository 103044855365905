import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ModalController, NavController } from '@ionic/angular';
import { NavParams } from '@ionic/angular';
import { ActivatedRoute } from '@angular/router';
import { UserService } from 'src/app/services/user/user.service';
import { PrefService } from 'src/app/services/common/pref.service';

@Component({
  selector: 'app-showingfeedback',
  templateUrl: './showingfeedback.page.html',
  styleUrls: ['./showingfeedback.page.scss'],
})
export class ShowingfeedbackPage implements OnInit {
  feedbackDetails: FormGroup;
  user: any;
  id: any;
  agentName: any;
  public value = this.navParams.data;
  constructor(
    public modalCtrl: ModalController,
    public navCtrl: NavController,
    public userService: UserService,
    public route: ActivatedRoute,
    private navParams: NavParams,
    private pref: PrefService
  ) {
    this.feedbackDetails = new FormGroup({
      interested: new FormControl(
        '',
        Validators.compose([Validators.required])
      ),
      // appUse: new FormControl("", Validators.required),
      rate: new FormControl('', Validators.required),
      // 'price':new FormControl('', Validators.required),
      experienced: new FormControl('', Validators.required),
      comments: new FormControl(''),
      notification: new FormControl(true),
    });
  }

  ngOnInit() {}

  ionViewWillEnter() {
    this.user = this.pref.getStorageItem('user_res');
    if (this.user != undefined && this.user != null) {
      this.agentName = this.user.user_display_name;
    } else {
      this.navCtrl.navigateForward(['/login']);
    }
  }

  dismissModal() {
    // using the injected ModalController this page
    // can "dismiss" itself and optionally pass back data
    this.modalCtrl.dismiss({
      dismissed: true,
    });
  }

  submitFeedback(values) {
    this.userService.showLoader();
    let feedbackValues = {
      'Is the client interested in this listing': values.interested,
      'Please rate the overall experience at this showing': values.experienced,
      'Please rate this listing (5=Best; 1=Worst)': values.rate,
      'COMMENTS/RECOMMENDATIONS (about the showing/property)': values.comments,
      // "How likely are you to use the Home Showing Pros app again":
      //   values.appUse,
    };
    var obj = {};
    obj['token'] = this.user.token;
    (obj['author_id'] = parseInt(this.value.author_id)),
      (obj['showing_id'] = parseInt(this.value.showing_id)),
      (this.id = this.value.showing_id);
    (obj['booking_id'] = this.value.booking_id),
      (obj['client_in_list'] = values.interested),
      // obj['opinion_of_price'] = values.price,
      (obj['rate_the_listing'] = values.rate),
      (obj['comments'] = values.comments),
      // (obj["app_use"] = values.appUse);
      (obj['experienced'] = values.experienced);
    obj['send_notification'] = values.notification;
    (obj['msg'] = this.agentName + ' has completed showing and sent Feedback'),
      (obj['feedback'] = feedbackValues);
    this.userService.sendFeedback(obj).subscribe(
      (res) => {
        if (res) {

          this.completeShowing(feedbackValues);
          // this.navCtrl.navigateForward(['/agentrating/'+ this.value.showing_id]);
        } else {
          this.userService.dismissLoading();
          this.userService.presentAlert('Something went Wrong');
        }
      },
      (err) => {
        this.userService.dismissLoading();
        this.userService.presentAlert(err.message);
      }
    );
  }

  completeShowing(feedbackValues) {
    var obj = {
      id: this.id,
      status: 'complete',
      message: this.agentName + ' has completed the showing',
      token: this.user.token,
      feedback: feedbackValues,
    };

    this.userService.completeShowingApi(obj).subscribe(
      (res) => {
        if (res) {
          this.userService.dismissLoading();
          this.userService.presentAlert(
            'This showing is now completed.' +
              '<br>' +
              'Thank you for using Home Show Pros. Your payment is now processing.'
          );
          this.dismissModal();

          if (this.value.author_id == this.user.user_id) {
            this.navCtrl.navigateForward(['/agentrating/' + this.id]); //128508
          } else {
            this.navCtrl.navigateForward(['/tabs/dashboard']);
          }
        } else {
          this.userService.dismissLoading();
          this.userService.presentAlert('Something went Wrong');
        }
      },
      (err) => {
        this.userService.dismissLoading();
        this.userService.presentAlert(err.message);
      }
    );
  }
}
