import { Injectable } from "@angular/core";
import { Router } from "@angular/router";

import { StorageEngineService } from "./storage-engine.service";
import { Events } from "./events.service";
import { PlatformLocation } from "@angular/common";

@Injectable()
export class PrefService {
  isLoggedIn = false;
  token: any;
  storageValue: any;
  public storageEngine: any;
  public awsService: any;
  public cognitoService: any;
  public healthService: any;

  constructor(
    public storageEngineService: StorageEngineService,
    public events: Events,
    public router: Router,
    public platformLocation: PlatformLocation
  ) {
    this.storageEngine = this.storageEngineService.getStorageEngine();
  }

  getEventService() {
    return this.events;
  }

  public getStorageEngine() {
    return this.storageEngine;
  }

  clearStorage() {
    this.storageEngine.clear();
  }

  getStorageItem(key: string) {
    return this.storageEngine.getItem(key);
  }

  removeStorageItem(key: string) {
    this.storageEngine.removeItem(key);
  }

  setStorageItem(prop: any, value: any) {
    this.storageEngine.setItem(prop, value);
  }

  cleanAppData() {
    this.storageEngine.removeItem("user_res");
    this.storageEngine.removeItem("carddetail");
    this.storageEngine.removeItem("showingFormMedia");
    this.storageEngine.removeItem("showingForm");
    this.storageEngine.removeItem("showingFirmList");
    this.storageEngine.removeItem("address");
    this.storageEngine.removeItem("kyc_status");

  }
}
