// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  firebase: {
    apiKey: "AIzaSyAaGBZftwGEQtOmKdRQV0-VyoNLS7rI9zU",
    authDomain: "home-showing-5e690.firebaseapp.com",
    databaseURL: "https://home-showing-5e690.firebaseio.com",
    projectId: "home-showing-5e690",
    storageBucket: "home-showing-5e690.appspot.com",
    messagingSenderId: "95098940086",
    appId: "1:95098940086:web:531fe358975ee0fd8c1417",
    measurementId: "G-LM5QGJN2W1",
    vapidKey:
      "BPdPRamSjn86RT8ApS8HslgYk3NFfX_-VLyTL2A390eG7BqAIQW4r3DF-nhDXa1M0rLXVFAlNkpi_--ZdgxXE3A",
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
