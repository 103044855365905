import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { RouteReuseStrategy, RouterModule, Routes } from "@angular/router";
import { IonicRatingModule } from "ionic4-rating";
import { IonicModule, IonicRouteStrategy } from "@ionic/angular";
import { SplashScreen } from "@ionic-native/splash-screen/ngx";
import { StatusBar } from "@ionic-native/status-bar/ngx";
import { ReactiveFormsModule } from "@angular/forms";
import { AppComponent } from "./app.component";
import { AppRoutingModule } from "./app-routing.module";
import { NativeStorage } from "@ionic-native/native-storage/ngx";
import { HttpClientModule } from "@angular/common/http";
import {
  Camera,
  CameraOptions,
  PictureSourceType,
} from "@ionic-native/camera/ngx/index";
import { File } from "@ionic-native/file/ngx";
import { FilePath } from "@ionic-native/file-path/ngx/index";
import {
  FileTransfer,
  FileUploadOptions,
  FileTransferObject,
} from "@ionic-native/file-transfer/ngx";
import { Stripe } from "@ionic-native/stripe/ngx/index";
import { ServiceWorkerModule } from "@angular/service-worker";
import { environment } from "../environments/environment";
import { Geolocation } from "@ionic-native/geolocation/ngx";
import {
  NativeGeocoder,
  NativeGeocoderResult,
  NativeGeocoderOptions,
} from "@ionic-native/native-geocoder/ngx";
import { AngularFireModule } from "@angular/fire";
import { AngularFireMessagingModule } from "@angular/fire/messaging";
import { NgxIonicImageViewerModule } from "ngx-ionic-image-viewer";
import { TooltipModule, TooltipOptions } from "ng2-tooltip-directive";
import { SharedModule } from "./shared";
import { StorageEngineService } from "./services/common/storage-engine.service";
import { LocalStorageService } from "./services/common/local-storage.service";
import { PrefService } from "./services/common/pref.service";
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';

const routes: Routes = [];

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    SharedModule,
    IonicRatingModule,
    RouterModule.forRoot(routes, { useHash: true }),
    // RouterModule.forRoot(routes, { useHash: false }),
    TooltipModule,
    HttpClientModule,
    NgxIonicImageViewerModule,
    ReactiveFormsModule,
    ServiceWorkerModule.register("combined-sw.js", {
      enabled: environment.production,
    }),
    AngularFireModule.initializeApp(environment["firebase"]),
    AngularFireMessagingModule,
  ],
  providers: [
    StatusBar,
    SplashScreen,
    Camera,
    File,
    FilePath,
    FileTransfer,
    Stripe,
    NativeGeocoder,
    NativeStorage,
    Geolocation,
    StorageEngineService,
    LocalStorageService,
    PrefService,
    InAppBrowser,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
