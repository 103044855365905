import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { ModalController, NavController, NavParams } from "@ionic/angular";
import { PrefService } from "src/app/services/common/pref.service";
import { UserService } from "src/app/services/user/user.service";

@Component({
  selector: "app-reschedule-showing",
  templateUrl: "./reschedule-showing.page.html",
  styleUrls: ["./reschedule-showing.page.scss"],
})
export class RescheduleShowingPage implements OnInit {
  user: any;
  agentName: any;
  rescheduleForm: FormGroup;
  public value = this.navParams.data;
  constructor(
    public modalCtrl: ModalController,
    public navCtrl: NavController,
    public userService: UserService,
    public route: ActivatedRoute,
    public router: Router,
    private navParams: NavParams,
    private pref: PrefService
  ) {
    this.rescheduleForm = new FormGroup({
      date: new FormControl("", Validators.required),
      time: new FormControl("", Validators.required),
    });
  }

  ngOnInit() {}

  ionViewWillEnter() {
    this.user = this.pref.getStorageItem("user_res");
    if (this.user != undefined && this.user != null) {
      this.agentName = this.user.user_display_name;
    } else {
      this.navCtrl.navigateForward(["/login"]);
    }

  }

  dismissModal() {
    this.modalCtrl.dismiss({
      dismissed: true,
    });
  }

  submitReschedule() {
    this.userService.showLoader();
    var d = new Date(this.rescheduleForm.controls.time.value);
    var rescheduleTime = d.toLocaleString([], {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    });

    var d2 = new Date(this.rescheduleForm.controls.date.value);
    var rescheduleDate = d2.toLocaleString("default", {
      weekday: "long",
      month: "long",
      day: "2-digit",
      year: "numeric",
    });

    var rescheduleObj = {
      time: rescheduleTime,
      date: rescheduleDate,
      token: this.value.token,
      showing_id: this.value.showing_id,
      booking_id: this.value.booking_id,
    };
    this.userService.sendData("rescheduleBooking", rescheduleObj).subscribe(
      (res) => {
        if (res) {
          this.dismissModal();
          this.userService.presentAlert(res["msg"]);
          this.userService.dismissLoading();
        }
      },
      (err) => {
        this.userService.dismissLoading();
        this.userService.presentAlert("Something is wrong Try again");
      }
    );
  }
}
