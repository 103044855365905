import {Injectable} from '@angular/core';

import {NativeStorage} from '@ionic-native/native-storage/ngx';
import {LocalStorageService} from './local-storage.service';
import {Platform} from '@ionic/angular';

@Injectable()
export class StorageEngineService {
  public storageEngine: any;

  constructor(
    private platform: Platform,
    public storage: NativeStorage,
    public localStorage: LocalStorageService
  ) {
    if (this.platform.is('cordova') || this.platform.is('desktop') || this.platform.is('mobile')) {
      this.storageEngine = this.localStorage;
    } else {
      this.storageEngine = this.storage;
    }
  }

  getStorageEngine() {
    return this.storageEngine;
  }
}
