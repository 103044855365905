import { Component, NgZone, OnInit } from "@angular/core";
import { PopoverController, NavParams } from "@ionic/angular";

@Component({
  selector: "app-popup-review",
  templateUrl: "./popup-review.component.html",
  styleUrls: ["./popup-review.component.scss", "./../review/review.page.scss"],
})
export class PopupReviewComponent implements OnInit {
  public sData: any;
  starBox = [1, 2, 3, 4, 5];

  constructor(
    private popoverController: PopoverController,
    private navParams: NavParams,
    private zone: NgZone
  ) {}

  ngOnInit() {
    this.sData = this.navParams.data.selectedData;
  }

  async goClose() {
    await this.popoverController.dismiss();
  }
}
