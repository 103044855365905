import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  NgZone,
} from "@angular/core";
import {
  ModalController,
  LoadingController,
  AlertController,
  NavController,
  NavParams,
  Platform,
} from "@ionic/angular";
import { Geolocation } from "@ionic-native/geolocation/ngx";

import { Router } from "@angular/router";
import { Location } from "@angular/common";

declare var google: any;

@Component({
  selector: "app-location-select",
  templateUrl: "./location-select.page.html",
  styleUrls: ["./location-select.page.scss"],
})
export class LocationSelectPage implements OnInit {
  @ViewChild("map") mapElement: ElementRef;
  @ViewChild("places")
  public places: ElementRef;
  public state_id: any;
  public screen: any;
  map: any;
  public address: string;
  address_json: any;
  role: any;
  autocomplete: any;
  GoogleAutocomplete: any;
  GooglePlaces: any;
  geocoder: any;
  autocompleteItems: any;
  nearbyItems: any = new Array<any>();
  loading: any;
  markers: any;
  latitude: any;
  longitude: any;
  public state_name: any;
  public city_name: any;
  public zipcode: any;
  public street2: any;
  public country: any;
  public street3: any;
  public state: any;
  street_number: any;
  route: any;
  send_address: any;
  premise: any;
  place_latitude: any;
  place_longitude: any;
  street1: any;
  pref_lat: any;
  pref_long: any;
  user_id: any;

  constructor(
    private geolocation: Geolocation,
    public params: NavParams,
    public alertCtrl: AlertController,
    private router: Router,
    public zone: NgZone,
    public loadingCtrl: LoadingController,
    public navCtrl: NavController,
    public modal: ModalController,
    public platform: Platform,
    public location: Location
  ) {
    this.markers = [];
    this.geocoder = new google.maps.Geocoder();
    let elem = document.createElement("div");
    this.GooglePlaces = new google.maps.places.PlacesService(elem);
    this.GoogleAutocomplete = new google.maps.places.AutocompleteService();
    this.autocomplete = {
      input: "",
    };
    this.autocompleteItems = [];
    this.latitude = params.get("latitude");
    this.longitude = params.get("longitude");
  }

  ngOnInit() {}

  dismissModal() {
    if (this.screen == "Business Map") {
      this.modal.dismiss();
    } else {
      this.modal.dismiss();
    }
  }

  ionViewWillEnter() {
    this.loadMap();
  }

  loadMap() {
    this.zone.run(() => {
      let options = { enableHighAccuracy: true, maximumAge: 60000 };
      this.geolocation
        .getCurrentPosition(options)
        .then((resp) => {
          let latLng = new google.maps.LatLng(
            resp.coords.latitude,
            resp.coords.longitude
          );
          let mapOptions = {
            center: latLng,
            zoom: 15,
            mapTypeId: google.maps.MapTypeId.ROADMAP,
            enableHighAccuracy: true,
            myLocationButton: true,
            streetViewControl: false,
            disableDefaultUI: true,
          };
          this.map = new google.maps.Map(
            this.mapElement.nativeElement,
            mapOptions
          );
          if (
            this.latitude != undefined &&
            this.latitude != "" &&
            this.longitude != undefined &&
            this.longitude != ""
          ) {
            this.place_latitude = parseFloat(this.latitude);
            this.place_longitude = parseFloat(this.longitude);

            let position = {
              lat: this.place_latitude,
              lng: this.place_longitude,
            };
            this.getAddressFromLatLong(
              parseFloat(this.latitude),
              parseFloat(this.longitude)
            );
            this.map.setCenter(position);
          } else {
            this.getAddressFromLatLong(
              this.map.center.lat(),
              this.map.center.lng()
            );
          }
          this.map.addListener("drag", () => {
            this.getAddressFromLatLong(
              this.map.center.lat(),
              this.map.center.lng()
            );
          });
        })
        .catch((error) => {
          if (error.code == 1) {
            this.presentAlert("User denied geolocation permission.");
          } else {
            this.presentAlert("Unable to get your location.");
          }
        });
    });
  }
  public async presentAlert(msg: any) {
    let alert = await this.alertCtrl.create({
      header: "Alert",
      message: msg,
      buttons: ["OK"],
    });

    await alert.present();
  }
  updateSearchResults() {
    if (this.autocomplete.input == "") {
      this.autocompleteItems = [];
      return;
    }
    this.GoogleAutocomplete.getPlacePredictions(
      { input: this.autocomplete.input },
      (predictions, status) => {
        this.autocompleteItems = [];
        this.zone.run(() => {
          predictions.forEach((prediction) => {
            this.autocompleteItems.push(prediction);
          });
        });
      }
    );
  }

  selectSearchResult(item) {
    this.zone.run(() => {
      this.address = "loading address";
      this.autocompleteItems = [];
      this.geocoder.geocode({ placeId: item.place_id }, (results, status) => {
        if (status === "OK" && results[0]) {
          let position = {
            lat: results[0].geometry.location.lat,
            lng: results[0].geometry.location.lng,
          };
          this.map.setCenter(results[0].geometry.location);
          this.getAddressFromLatLong(
            results[0].geometry.location.lat(),
            results[0].geometry.location.lng()
          );
          this.autocomplete = {
            input: "",
          };
        }
      });
    });
  }

  addMarker(map: any) {
    let marker = new google.maps.Marker({
      map: map,
      animation: google.maps.Animation.DROP,
      position: map.getCenter(),
    });
    let content = "<h4>Information!</h4>";
    this.addInfoWindow(marker, content);
  }

  addInfoWindow(marker, content) {
    let infoWindow = new google.maps.InfoWindow({
      content: content,
    });
    google.maps.event.addListener(marker, "click", () => {
      infoWindow.open(this.map, marker);
    });
  }

  getAddress() {
    let data = {
      // string1: this.state_name,
      state_id: this.state_id,
      state: this.state_name,
      string2: this.city_name,
      string3: this.zipcode,
      string4: this.latitude,
      string5: this.longitude,
      formatted_address: this.address,
      street: this.street1,
      colony: this.street2,
      country: this.country,
    };
    this.modal.dismiss(data);
  }

  async showLoader() {
    this.loading = await this.loadingCtrl.create({
      message: "Loading map....",
    });
    this.loading.present();
  }

  getAddressFromLatLong(latitude, longitude) {
    let latLng = { lat: latitude, lng: longitude };
    this.geocoder.geocode({ location: latLng }, (results, status) => {
      if (status == "OK" && results) {
        this.zone.run(() => {
          this.state_name = "";
          this.state_id = "";
          this.city_name = "";
          this.zipcode = "";
          this.street2 = "";
          this.address = results[0].formatted_address;
          this.latitude = results[0].geometry.location.lat();
          this.longitude = results[0].geometry.location.lng();
          for (var i = 0; i < results[0].address_components.length; i++) {
            for (
              var j = 0;
              j < results[0].address_components[i].types.length;
              j++
            ) {
              if (
                results[0].address_components[i].types[j] ==
                "administrative_area_level_1"
              ) {
                var name = results[0].address_components[i];
                this.state_name = name.long_name;
                this.state_id = name.short_name;
              } else if (
                results[0].address_components[i].types[j] == "locality"
              ) {
                var name = results[0].address_components[i];
                this.city_name = name.long_name;
              } else if (
                results[0].address_components[i].types[j] == "postal_code"
              ) {
                var name = results[0].address_components[i];
                this.zipcode = name.long_name;
              } else if (
                results[0].address_components[i].types[j] == "sublocality" ||
                results[0].address_components[i].types[j] == "neighborhood"
              ) {
                var name = results[0].address_components[i];
                this.street2 = name.long_name;
              } else if (
                results[0].address_components[i].types[j] == "premise" ||
                results[0].address_components[i].types[j] == "street_number" ||
                results[0].address_components[i].types[j] ==
                  "point_of_interest" ||
                results[0].address_components[i].types[j] == "bus_station"
              ) {
                var name = results[0].address_components[i];
                this.premise = name.long_name;
                if (this.premise == undefined) {
                  this.premise == "";
                } else {
                  this.street1 = this.premise;
                }
              } else if (results[0].address_components[i].types[j] == "route") {
                var name = results[0].address_components[i];
                this.route = name.long_name;
                if (this.route == undefined || this.premise == undefined) {
                  this.route == "";
                  this.premise = "";
                } else {
                  this.street1 = this.premise + "," + this.route;
                }
              } else if (
                results[0].address_components[i].types[j] == "country" ||
                results[0].address_components[i].types[j] == "political"
              ) {
                var name = results[0].address_components[i];
                this.country = name.long_name;
              }
            }
          }
        });
      }
    });
  }
}
