import { Injectable } from "@angular/core";
import {
  AlertController,
  LoadingController,
  NavController,
  ModalController,
  Platform,
} from "@ionic/angular";
import { Router, NavigationExtras, ActivatedRoute } from "@angular/router";
import * as moment from "moment";
import * as momenttimezone from "moment-timezone";

@Injectable({
  providedIn: "root",
})
export class CommonService {
  public fulldate: any;
  public fulldt: any;
  public dy: any;
  public ss: any;
  public mn: any;
  isLoading = false;
  public loading: any;


  constructor(
    public alertCtrl: AlertController,
    public loadingCtrl: LoadingController,
    public navController: NavController,
    public modal: ModalController,
    public platform: Platform
  ) {}


  async dismiss() {
    return await this.loadingCtrl.dismiss();
  }


  public convertAdminTime(date: any) {
    var sdate = moment(date).format(
      "YYYY-MM-DD HH:mm:ss"
    );
    return sdate;
  }

  public convertdate(date: any) {
    var sdate = moment(date, "YYYY-MM-DDTHH:mm:ssTZD").format("YYYY/MM/DD");
    return sdate;
  }
  public convertPdate(date: any) {
    var sdate = moment(date, "MM/DD/YYYY").format("YYYY/MM/DD");
    return sdate;
  }

  public convertToPicker(date: any) {
    var sdate = moment(date, "YYYY-MM-DD").format("YYYY-MM-DDTHH:mmZ");
    return sdate;
  }
  public pickerConvertDate(date: any) {
    var sdate = moment(date, "YYYY/MM/DD").format("MM/DD/YYYY");
    return sdate;
  }



  formatDate(date) {
    let d = new Date(date),
      day = "" + d.getDate(),
      month = "" + (d.getMonth() + 1),
      year = d.getFullYear();
    if (month.length < 2) {
      month = "0" + month;
    }
    if (day.length < 2) {
      day = "0" + day;
    }
    return [year, month, day].join("-");
  }

  formatTime(date) {
    let d = new Date(date),
      hour = "" + d.getHours(),
      minute = "" + d.getMinutes(),
      second = "" + d.getSeconds();
    if (hour.length < 2) {
      hour = "0" + hour;
    }
    if (second.length < 2) {
      second = "0" + second;
    }
    if (minute.length < 2) {
      minute = "0" + minute;
    }
    return [hour, minute, second].join(":");
  }

  public getSystemDateTime() {
    const date = new Date();
    var fulldate = this.formatDate(date);
    var fulltime = this.formatTime(date);
    var fulldt = fulldate + " " + fulltime;
    return fulldt;
  }

  public getsystemdate() {
    let fulldate: any;
    let fulldt: any;
    var dt = new Date();
    let dy: any;
    let ss: any;
    let mn: any;
    var yr = dt.getFullYear();
    dy = dt.getDate();
    var tm = dt.getHours();
    var min = dt.getMinutes();
    ss = dt.getSeconds();
    if (dy < 10) {
      dy = "0" + dy;
    } else {
      dy = dy;
    }
    if (ss < 10) {
      ss = "0" + ss;
    } else {
      ss = ss;
    }
    mn = dt.getMonth() + 1;
    if (mn < 10) {
      mn = "0" + mn;
    } else {
      mn = mn;
    }
    fulldate = yr + "-" + mn + "-" + dy;
    fulldt = fulldate;
    return fulldt;
  }

  public getShowingTime(time) {
    var timeformat = moment(time).format("hh:mm A");
    return timeformat;
  }

  public getCalenderDate(cDate: any) {
    var timeformat = moment(cDate).format("MMM DD, YYYY");
    return timeformat;
  }
  public async presentAlert(msg: any) {
    let alert = await this.alertCtrl.create({
      header: "Alert",
      message: msg,
      backdropDismiss: false,
      buttons: ["OK"],
    });

    await alert.present();
  }

  public isOnline() {
    if (!navigator.onLine) {
      this.presentAlert("Please check your internet connection.");
      return false;
    } else {
      setTimeout(() => {
        if (!navigator.onLine) {
          this.presentAlert("Please check your internet connection.");
          return false;
        }
      }, 2000);
      return true;
    }
  }



  public getLicenseDate(date: any) {
    var sdate = moment(date, "YYYY/MM/DD").format("MM/DD/YYYY");
    return sdate;
  }

  public async confirmAlert(msg: any, page: any) {
    const alert = await this.alertCtrl.create({
      header: "Alert",
      message: msg,
      buttons: [
        {
          text: "Okay",
          handler: () => {
            this.navController.navigateForward([page]);
          },
        },
      ],
    });

    await alert.present();
    return false;
  }

  public getCurrentDateCalender() {
    let dt = moment().utc().format("YYYY-MM-DD");
    return dt;

  }

  public checkCharge(amt, charge, type) {
    if (type != "add") {
      return parseFloat(amt) - parseFloat(charge);

    } else {
      return parseFloat(amt) + parseFloat(charge);

    }
  }

}
