import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class LocalStorageService {
  private readonly prefix: string;


  constructor() {
    this.prefix = "hsw";
  }

  public setItem(prop: any, value: any) {
    const key = `${this.prefix}-${prop}`;
    localStorage.setItem(key, JSON.stringify(value));
  }

  public getItem(prop: any) {
    const key = `${this.prefix}-${prop}`;
    return JSON.parse(localStorage.getItem(key));
  }

  public removeItem(prop: any) {
    const key = `${this.prefix}-${prop}`;
    return localStorage.removeItem(key);
  }
}
